import axios from 'axios'

let API = axios.create({
    baseURL: "https://back.movadex.com/",
    headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data'
    }
});

export default API;
