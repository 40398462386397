import React, { useState } from "react"
import { Link } from "gatsby"

import catGlasses from "../assets/cat-glasses.svg"
import iconUpload from "../assets/icon-upload.svg"

import API from "../API"

import scrollTo from "gatsby-plugin-smoothscroll"

const FooterMail = props => {

  const InitialData = {
    name: "",
    mail: "",
    phone: "",
    field: "",
    text: "",
    newsUpdate: false,
  }

  const [mailData, setMailData] = useState(InitialData)
  const [mailFile, setMailFile] = useState("")
  const [mailDone, setMailDone] = useState(false)

  const [mailFileLimit, setMailFileLimit] = useState(false)

  const handleInputChange = e => {
    const { name, value } = e.target
    setMailData(prevState => ({ ...prevState, [name]: value }))
  }

  const handleCheckboxChange = () => {
    setMailData(prevState => ({ ...prevState, newsUpdate: !mailData.newsUpdate }))
  }

  const handleFileChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size < 50000000) {
        setMailFile(e.target.files[0])
        setMailFileLimit(false)
      } else {
        setMailFile("")
        setMailFileLimit(true)
      }
    }
  }

  const sendForm = e => {
    e.preventDefault()
    if (!mailData.mail) return

    const formData = new FormData()
    formData.append("type", "submission")
    formData.append("name", mailData.name)
    formData.append("email", mailData.mail)
    formData.append("phone", mailData.phone)
    formData.append("company_name", mailData.field)
    formData.append("description", mailData.text)
    formData.append("file", mailFile)

    if (props.pageName === "survey") {
      formData.append("quiz1", props.pageData.quizOne.map(({ name }) => name).join(", "))
      formData.append("quiz2", props.pageData.quizTwo.map(({ name }) => name).join(", "))
      formData.append("quiz3", props.pageData.quizThree.name)
      formData.append("quiz4", props.pageData.quizFour.name)
    }

    API.post("", formData)
      .then(res => {

        if (res.status === 200) {
          setMailData(InitialData)
          setMailFile("")
          setMailFileLimit(false)
          setMailDone(true)
          scrollTo("#footer-mail")

          setTimeout(() => {
            // setMailDone(false)


            if (props.pageName !== "career") {
              if (typeof window !== "undefined") {
                if (window.gtag_report_conversion != null) {
                  console.log("new lead");
                  window.gtag_report_conversion();
                }
                if (window.fbq != null) {
                  window.fbq("track", "Lead")
                }
              }
            }
          }, 3000)
        }
      })
  }

  const textBlock = pageName => {
    switch (pageName) {
      case "survey":
        return (
          <>
            <h6 className="footer-mail__subtitle">
              Step 5<span className="quiz__step--span">/5</span>
            </h6>

            <h2 className="footer-mail__title">
              Leave your contacts and we will provide free final estimation
            </h2>
          </>
        )
      case "career":
        return (
          <>
            <h6 className="footer-mail__subtitle">
              No matching position for you ?
            </h6>

            <h2 className="footer-mail__title">We want to hear you anyway</h2>
          </>
        )

      default:
        return (
          <>
            <h6 className="footer-mail__subtitle">
              Start your project with us now
            </h6>

            <h2 className="footer-mail__title">
              Let’s build the greatest experience for your customers
            </h2>
          </>
        )

    }
  }

  return (
    <div id="footer-mail" className={`footer-mail${mailDone ? " footer-mailDone" : ""}`}>
      {mailDone ?
        <h2 className="footer-mailDone__sent">{`Thank you!\nWe will be in touch with you soon!`}</h2>
        :
        <div className="footer-mail__wrapper container">
          <div className="footer-mail__block">
            {textBlock(props.pageName ? props.pageName : "")}

            <p className="footer-mail__text">
              Email us to{" "}
              <a className="footer-mail__link" href="mailto:nor@movadex.com">
                nor@movadex.com
              </a>
            </p>

            <p className="footer-mail__text">
              or call{" "}
              <a className="footer-mail__link" href="tel:+19292188640">
                +1 929-218-8640
              </a>
            </p>
          </div>

          <div className="footer-mail__block">
            <form className="footer-form" onSubmit={sendForm}>
              <div className="footer-form__block">
                <input
                  className="footer-form__input"
                  type="text"
                  name="name"
                  value={mailData.name}
                  onChange={handleInputChange}
                  placeholder="Name (optional)"
                />

                <input
                  className="footer-form__input"
                  type="email"
                  name="mail"
                  value={mailData.mail}
                  onChange={handleInputChange}
                  placeholder="E-mail"
                />

                <input
                  className="footer-form__input"
                  type="tel"
                  name="phone"
                  value={mailData.phone}
                  onChange={handleInputChange}
                  placeholder="Phone (optional)"
                />

                <input
                  className="footer-form__input"
                  type="text"
                  name="field"
                  value={mailData.field}
                  onChange={handleInputChange}
                  placeholder={`${
                    props.pageName === "career"
                      ? "Your field"
                      : "Company name (optional)"
                  }`}
                />
              </div>

              <textarea
                className="footer-form__textarea"
                name="text"
                value={mailData.text}
                onChange={handleInputChange}
                placeholder={`${
                  props.pageName === "career"
                    ? "Tell us about yourself"
                    : "Describe your project (optional)"
                }`}
              />
              <div className="footer-form__block">
                <label className="footer-form-checkbox">
                  <input
                    className="footer-form-checkbox__input"
                    type="checkbox" checked={mailData.newsUpdate} onChange={handleCheckboxChange}
                  />
                  <span className="footer-form-checkbox__span"/>
                  <span className="footer-form-checkbox__text">
                  I want to receive news and updates
                </span>
                </label>

                <label className="footer-form-file">
                  <input className="footer-form-file__input" type="file" onChange={handleFileChange}/>
                  <span className="footer-form-file__span">
                  <img
                    className="footer-form-file__icon"
                    src={iconUpload}
                    alt=""
                  />
                </span>
                  <span className={`footer-form-file__text${mailFileLimit ? " error" : ""}`}>
                  {mailFile ? mailFile.name : mailFileLimit ? `Files must be less than 50 MB` : `Attach ${props.pageName === "career" ? "CV" : "file"}`}
                </span>
                </label>
              </div>

              <div className="footer-form__block">
                <button className="footer-form__submit">Send</button>

                {props.pageName === "career" || props.pageName === "survey" ? null : (
                  <Link to="/survey/" className="footer-form__link">
                    Try our interactive survey
                  </Link>
                )}
              </div>
            </form>

            <img className="footer-mail__cat" src={catGlasses} alt=""/>
          </div>
        </div>}
    </div>
  )
}

export default FooterMail
