import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import worldImage from "../assets/world-map.svg"

import iconBehance from "../assets/icon-behance.svg"
import iconDribble from "../assets/icon-dribble.svg"
import iconLinkedin from "../assets/icon-linkedin.svg"
import iconFacebook from "../assets/icon-facebook.svg"
import iconInstagram from "../assets/icon-instagram.svg"

const Footer = ({ children }) => {

  const query = useStaticQuery(graphql`
    query FooterQuery {
        allCareerDataJson(limit: 3) {
            edges {
                node {
                    id
                    title
                }
            }
        }
    }
    `)

  const careerData = query.allCareerDataJson.edges

  return (
    <footer className="footer">
      {children}

      <div className="footer-main container">
        <div className="footer-main__wrapper">
          <h2 className="footer-main__title">Design and software development <span className="footer-main__title_blue">done right</span>
          </h2>

          <div className="footer-main-nav">
            <ul className="footer-main-nav__list">
              <li className="footer-main-nav__item">Website</li>
              <li className="footer-main-nav__item"><Link to="/about/" className="footer-main-nav__link">About us</Link>
              </li>
              <li className="footer-main-nav__item"><Link to="/survey/" className="footer-main-nav__link">Try
                this</Link></li>
              <li className="footer-main-nav__item"><Link to="/career/" className="footer-main-nav__link">We are
                hiring</Link></li>
              <li className="footer-main-nav__item"><Link to="/blog/" className="footer-main-nav__link">Blog</Link></li>

              <li className="footer-main-nav__item "><a href="https://creative.movadex.com" className="footer-main-nav__link__creative footer-main-nav__link ">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                  <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="0%" className={"footer-main-nav__link__creative__gradcolor1"} />
                      <stop offset="100%" className={"footer-main-nav__link__creative__gradcolor2"} />
                    </linearGradient>
                  </defs>
                  <text fill="url(#grad1)" x="0" y="16">
                    Movadex&nbsp;Creative</text>
                </svg>
              </a></li>
            </ul>

            <ul className="footer-main-nav__list">
              <li className="footer-main-nav__item">Services</li>
              <li className="footer-main-nav__item"><Link to="/services/web-development/"
                                                          className="footer-main-nav__link">Web development</Link></li>
              <li className="footer-main-nav__item"><Link to="/services/mobile-development/"
                                                          className="footer-main-nav__link">Mobile development</Link>
              </li>
              <li className="footer-main-nav__item"><Link to="/services/ui-ux-design/"
                                                          className="footer-main-nav__link">UI/UX design</Link></li>
              <li className="footer-main-nav__item"><Link to="/services/branding-and-ads/"
                                                          className="footer-main-nav__link">Product design</Link></li>
              <li className="footer-main-nav__item"><Link to="/services/quality-assurance/"
                                                          className="footer-main-nav__link">QA Testing</Link></li>
            </ul>

            <ul className="footer-main-nav__list">
              <li className="footer-main-nav__item">Opportunities</li>
              {careerData.map(({ node }) =>
                <li key={node.id} className="footer-main-nav__item"><Link to="/career/"
                                                                          className="footer-main-nav__link">{node.title}</Link>
                </li>)}
            </ul>

            <div className="footer-main-nav-media">
              <a href="https://www.behance.net/Movadex" className="footer-main-nav-media__link" target="_blank"
                 rel="noopener noreferrer">
                <img className="footer-main-nav-media__icon" src={iconBehance} alt=""/>
              </a>

              <a href="https://dribbble.com/movadex" className="footer-main-nav-media__link" target="_blank"
                 rel="noopener noreferrer">
                <img className="footer-main-nav-media__icon" src={iconDribble} alt=""/>
              </a>

              <a href="https://www.linkedin.com/company/movadex" className="footer-main-nav-media__link" target="_blank"
                 rel="noopener noreferrer">
                <img className="footer-main-nav-media__icon" src={iconLinkedin} alt=""/>
              </a>

              <a href="https://www.facebook.com/movadex/" className="footer-main-nav-media__link" target="_blank"
                 rel="noopener noreferrer">
                <img className="footer-main-nav-media__icon" src={iconFacebook} alt=""/>
              </a>

              <a href="https://www.instagram.com/movadex" className="footer-main-nav-media__link" target="_blank"
                 rel="noopener noreferrer">
                <img className="footer-main-nav-media__icon" src={iconInstagram} alt=""/>
              </a>
            </div>
          </div>
        </div>

        <div className="footer-main__wrapper">
          <div className="footer-main-info">
            <p className="footer-main-info__text">We are located in central Europe
              {/*and USA*/}
            </p>

            <div className="footer-main-info__block">
              <a className="footer-main-info__link_blue" href="https://goo.gl/maps/GLbBqhckbnL33neU9" target="_blank"
                 rel="noopener noreferrer">
                Lviv, Ukraine</a>

              {/*<a className="footer-main-info__link_blue" href="https://goo.gl/maps/4SYVnjngTxDcrXDS7" target="_blank"*/}
              {/*   rel="noopener noreferrer">*/}
              {/*  Washington D.C.,{"\n"}*/}
              {/*  USA*/}
              {/*</a>*/}
            </div>

            <a className="footer-main-info__link" href="mailto:nor@movadex.com">
              nor@movadex.com
            </a>

            <a className="footer-main-info__link" href="mailto:salome@movadex.com">
              salome@movadex.com
            </a>
          </div>

          <div className="footer-main__world_flex">
            <img className="footer-main__world" src={worldImage} alt=""/>
          </div>
        </div>

        <div className="footer-main__wrapper">
          <p className="footer-main__data">Movadex {new Date().getFullYear()}. All rights reserved.</p>

          <a className="footer-main__data" target="_blank" href="/terms-conditions.html">Terms of use</a>

          <a className="footer-main__data" target="_blank" href="/privacy-policy.html">Privacy policy</a>
        </div>
      </div>

      <div className="footer-rights">
        <div className="footer-rights__wrapper container">
          <p className="footer-rights__text">Monthly pricing: Available to qualified customers and requires 0% APR,
            24-month installment loan with Citizens One or Apple Card Monthly Installments and iPhone activation with
            AT&T, Sprint, T-Mobile, or Verizon. Taxes and shipping not included. Additional Apple Card Monthly
            Installments terms are in the Customer Agreement. Additional iPhone Payments terms are here. . Trade In:
            Trade‑in values vary. iPhone 11 and iPhone 11 Pro promotional pricing is after trade‑in of iPhone 8 Plus and
            iPhone X in good condition. Additional trade‑in values require purchase of a new iPhone, subject to
            availability and limits.</p>

          <p className="footer-rights__text">Monthly pricing: Available to qualified customers and requires 0% APR,
            24-month installment loan with Citizens One or Apple Card Monthly Installments and iPhone activation with
            AT&T, Sprint, T-Mobile, or Verizon. Taxes and shipping not included. Additional Apple Card Monthly
            Installments terms are in the Customer Agreement. Additional iPhone Payments terms are here. . Trade In:
            Trade‑in values vary. iPhone 11 and iPhone 11 Pro promotional pricing is after trade‑in of iPhone 8 Plus and
            iPhone X in good condition. Additional trade‑in values require purchase of a new iPhone, subject to
            availability and limits.</p>

          <p className="footer-rights__text">Monthly pricing: Available to qualified customers and requires 0% APR,
            24-month installment loan with Citizens One or Apple Card Monthly Installments and iPhone activation with
            AT&T, Sprint, T-Mobile, or Verizon. Taxes and shipping not included. Additional Apple Card Monthly
            Installments terms are in the Customer Agreement. Additional iPhone Payments terms are here. . Trade In:
            Trade‑in values vary. iPhone 11 and iPhone 11 Pro promotional pricing is after trade‑in of iPhone 8 Plus and
            iPhone X in good condition. Additional trade‑in values require purchase of a new iPhone, subject to
            availability and limits.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
